import { Grid, Row, Col } from '@devstart/react-bootstrap';
import { graphql } from 'gatsby';
import type { TFunction } from 'i18next';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

// Local Utilities
import LearnLayout from '../../../components/layouts/learn';
import {
  AllChallengeNode,
  ChallengeMeta,
  ChallengeNode,
  User
} from '../../../redux/prop-types';
import ChallengeDescription from '../components/challenge-description';
import ChallengeTitle from '../components/challenge-title';
import CompletionModal from '../components/completion-modal';
import Hotkeys from '../components/hotkeys';
import {
  challengeMounted,
  openModal,
  submitChallenge,
  updateChallengeMeta
} from '../redux/actions';
import {
  completedChallengesIdsSelector,
  isChallengeCompletedSelector,
  isNotAllowedChallengeModalOpenSelector
} from '../redux/selectors';

// Styles
import {
  createFlashMessage,
  removeFlashMessage
} from '../../../components/Flash/redux';
import { userSelector } from '../../../redux/selectors';
import { changeTourStatus } from '../../../redux/actions';
import BreadCrumb from '../components/bread-crumb';
import '../video.css';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';
import ContentChallengeLayout from '../../../components/layouts/content-challenge-layout';
import AudioPlayer from '../components/audio-player';
import PdfDocument from '../components/pdf-document';

// Redux Setup
const mapStateToProps = createSelector(
  isChallengeCompletedSelector,
  completedChallengesIdsSelector,
  userSelector,
  isNotAllowedChallengeModalOpenSelector,
  (
    isChallengeCompleted: boolean,
    completedChallengeIds: string[],
    user: User,
    isNotAllowedChallenge: boolean
  ) => ({
    isChallengeCompleted,
    completedChallengeIds,
    user,
    isNotAllowedChallenge
  })
);
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateChallengeMeta,
      createFlashMessage,
      removeFlashMessage,
      challengeMounted,
      openCompletionModal: () => openModal('completion'),
      submitChallenge,
      changeTourStatus
    },
    dispatch
  );

// Types
interface ShowTextOnlyProps {
  challengeMounted: (arg0: string) => void;
  data: { challengeNode: ChallengeNode; allChallengeNode: AllChallengeNode };
  description: string;
  createFlashMessage: typeof createFlashMessage;
  removeFlashMessage: typeof removeFlashMessage;
  isChallengeCompleted: boolean;
  openCompletionModal: () => void;
  submitChallenge: () => void;
  pageContext: {
    challengeMeta: ChallengeMeta;
  };
  t: TFunction;
  updateChallengeMeta: (arg0: ChallengeMeta) => void;
  isNotAllowedChallenge: boolean;
}

interface ShowTextOnlyState {
  subtitles: string;
}

// Component
class ShowTextOnly extends Component<ShowTextOnlyProps, ShowTextOnlyState> {
  public static readonly displayName: string = 'ShowTextOnly';
  private _container: HTMLElement | null | undefined;

  constructor(props: ShowTextOnlyProps) {
    super(props);
    this.state = {
      subtitles: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(): void {
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta
    } = this.props;

    updateChallengeMeta({
      ...challengeMeta,
      title,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
    this._container?.focus();
  }

  componentDidUpdate(prevProps: ShowTextOnlyProps): void {
    const {
      data: {
        challengeNode: {
          challenge: { title: prevTitle }
        }
      }
    } = prevProps;
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title: currentTitle, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta
    } = this.props;
    if (prevTitle !== currentTitle) {
      updateChallengeMeta({
        ...challengeMeta,
        title: currentTitle,
        challengeType,
        helpCategory
      });
      challengeMounted(challengeMeta.id);
    }
  }

  componentWillUnmount(): void {
    const { removeFlashMessage } = this.props;

    const slug = this.props.data.challengeNode.challenge.fields.slug;
    if (window.location.pathname !== slug) {
      removeFlashMessage();
    }
  }

  handleSubmit(_: () => void, submitChallenge: () => void) {
    submitChallenge();
  }

  render() {
    const {
      data: {
        challengeNode: {
          challenge,
          challenge: {
            fields: { blockName },
            title,
            description,
            superBlock,
            certification,
            block,
            phase,
            extraContents
          }
        }
      },
      openCompletionModal,
      submitChallenge,
      pageContext: {
        challengeMeta: { nextChallengePath, prevChallengePath }
      },
      t,
      removeFlashMessage,
      isChallengeCompleted,
      isNotAllowedChallenge
    } = this.props;

    const audio = extraContents?.audio || null;
    const pdf = extraContents?.pdf || null;

    const blockNameTitle = `${t(
      `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
    )} - ${title}`;

    const breadCrumbItems = [
      {
        key: 'superblock-title',
        value: t(`intro:${superBlock}.title`),
        redirectsTo: `/learn/${superBlock}`
      },
      {
        key: 'superblock-phase',
        value: t(`intro:${superBlock}.phases.${phase}.title`),
        redirectsTo: `/learn/${superBlock}/#${block}`
      },
      {
        key: 'superblock-theme',
        value: t(`intro:${superBlock}.phases.${phase}.blocks.${block}.title`),
        redirectsTo: `/learn/${superBlock}/#${block}`
      },
      {
        key: 'superblock-challenge',
        value: title,
        redirectsTo: ''
      }
    ];

    return (
      <Hotkeys
        executeChallenge={() => {
          removeFlashMessage();

          this.handleSubmit(openCompletionModal, submitChallenge);
        }}
        innerRef={(c: HTMLElement | null) => (this._container = c)}
        nextChallengePath={nextChallengePath}
        prevChallengePath={prevChallengePath}
      >
        <LearnLayout challenge={challenge}>
          <Helmet
            title={`${blockNameTitle} | ${t('learn.learn')} | DEVStart`}
          />
          <ContentChallengeLayout
            disabledContinueButton={!isChallengeCompleted}
          >
            <Grid className='navigation-container-video'>
              <BreadCrumb breadCrumbItems={breadCrumbItems} />
            </Grid>
            <Grid className='container-video'>
              <Row>
                <Col sm={10} smOffset={1} xs={12}>
                  <ChallengeTitle>{title}</ChallengeTitle>
                  {audio && <AudioPlayer url={audio} />}
                  <ChallengeDescription
                    description={description}
                    style={{ padding: 0 }}
                  />
                  {pdf && <PdfDocument url={pdf} />}
                  <div className='video-quiz-options'>
                    {nextChallengePath ? (
                      <Button
                        buttonType={ButtonTypes.Quaternary}
                        id='big-button'
                        disabled={isNotAllowedChallenge}
                        onClick={() => {
                          removeFlashMessage();

                          this.handleSubmit(
                            openCompletionModal,
                            submitChallenge
                          );
                        }}
                      >
                        {t('buttons.go-to-next')}
                      </Button>
                    ) : null}
                  </div>
                  <CompletionModal
                    block={block}
                    blockName={blockName}
                    certification={certification}
                    superBlock={superBlock}
                  />
                </Col>
              </Row>
            </Grid>
          </ContentChallengeLayout>
        </LearnLayout>
      </Hotkeys>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShowTextOnly));

export const query = graphql`
  query TextOnlyChallenge($slug: String!) {
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        title
        description
        phase
        challengeType
        helpCategory
        superBlock
        certification
        extraContents {
          pdf
          audio
        }
        superOrder
        phaseOrder
        order
        challengeOrder
        block
        fields {
          blockName
          slug
        }
        translationPending
      }
    }
  }
`;
